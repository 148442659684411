<template>
  <!-- NOTIFICATIONS -->
  <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
    <feather-icon
      icon="BellIcon"
      class="cursor-pointer mt-1 sm:mr-6 mr-2"
      :badge="notificationNumber"
    />

    <vs-dropdown-menu
      class="notification-dropdown dropdown-custom vx-navbar-dropdown"
      v-if="!loading"
    >
      <div class="notification-top text-center p-5 bg-primary text-white">
        <h3 class="text-white">
          {{
            unreadNotifications.length == 0 || unreadNotifications.length == 1
              ? `${notificationNumber} Notification`
              : `${notificationNumber} Notifications`
          }}
        </h3>
      </div>

      <VuePerfectScrollbar
        ref="mainSidebarPs"
        class="scroll-area--nofications-dropdown p-0 "
        :settings="settings"
        :key="$vs.rtl"
      >
        <ul v-if="unreadNotificationsMin.length > 0" class="bordered-items">
          <li
            v-for="notif in unreadNotificationsMin"
            :key="notif.id"
            class="flex justify-between px-4 py-4 notification cursor-pointer"
          >
            <div class="flex items-start" @click="readNotification(notif)">
              <div class="mx-2">
                <span class="text-danger font-medium block notification-title"
                  >{{ getModuleName(notif.moduleName) }} ( {{ notif.counter }} )
                </span>
                <small>un nouvelle notification</small>
              </div>
            </div>
            <small class="mt-1 whitespace-no-wrap">{{
              elapsedTime(notif.createdAt)
            }}</small>
          </li>
        </ul>
      </VuePerfectScrollbar>

      <div
        v-show="unreadNotifications.length > 5 && hideViewAll == false"
        class="
        checkout-footer
        bottom-0
        rounded-b-lg
        text-primary
        w-full
        p-2
        font-semibold
        text-center
        border
        border-b-0
        border-l-0
        border-r-0
        border-solid
        d-theme-border-grey-light
        cursor-pointer"
      >
        <span @click="viewAll">Voir tout</span>
      </div>
    </vs-dropdown-menu>
    <vs-dropdown-menu class="notification-dropdown dropdown-custom vx-navbar-dropdown" v-else>
      <div>
        <clip-loader :loading="loading" color="primary"></clip-loader>
      </div>
    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import VueCrontab from "vue-crontab";
import { mapActions, mapGetters } from "vuex";
import { mixin } from "@/mixins/mixin";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  components: {
    VuePerfectScrollbar,
    VueCrontab,
    ClipLoader
  },
  data() {
    return {
      loading: false,
      hideViewAll: false,
      notificationNumber: 0,
      moduleList: [
        { id: "ticket", label: "Ticket" },
        { id: "commentTicket", label: "Ticket : Commentaire" }
      ],
      unreadNotifications: [],
      unreadNotificationsMin: [],
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    };
  },
  computed: {
    ...mapGetters("auth", ["userId"])
  },
  created() {
    this.getUnreadNotification();
    let result = VueCrontab.addJob({
      name: "counter",
      interval: {
        seconds: "0",
        minutes: "/1"
      },
      job: this.getUnreadNotification
    });
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    ...mapActions("general", [
      "applyGetAllNotification",
      "applyReadNotification"
    ]),

    getUnreadNotification() {
      //this.fetchApplyGetAllNotification();
    },

    viewAll() {
      this.unreadNotificationsMin = this.unreadNotifications;
      this.hideViewAll = true;
    },

    getModuleName(notification) {
      return this.moduleList.find(el => el.id == notification)
        ? this.moduleList.find(el => el.id == notification).label
        : "";
    },

    orderNotification(notifList) {
      this.notificationNumber = notifList.length;
      let tmpNotifListByName = this.orderNotificationByKey(
        notifList.filter(el => el.moduleName != "commentTicket"),
        "moduleName"
      );
      let tmpNotifListById = this.orderNotificationByKey(
        notifList.filter(el => el.moduleName == "commentTicket"),
        "itemId"
      );

      this.unreadNotifications = mixin.methods.filterJson(
        tmpNotifListByName.concat(tmpNotifListById),
        "createdAt"
      );

      this.loadMinValue();
    },
    groupBy(tableauObjets, propriete) {
      return tableauObjets.reduce(function(acc, obj) {
        var cle = obj[propriete];
        if (!acc[cle]) {
          acc[cle] = [];
        }
        acc[cle].push(obj);
        return acc;
      }, {});
    },

    orderNotificationByKey(notifList, key) {
      let itemListName = this.groupBy(notifList, key);
      let objectKeyList = Object.keys(itemListName);
      let itemList = [];

      objectKeyList.forEach(item => {
        if (item != "") {
          itemList.push({
            ...itemListName[item][itemListName[item].length - 1],
            counter: itemListName[item].length,
            list: itemListName[item]
          });
        }
      });

      return itemList;
    },

    async fetchApplyGetAllNotification() {
      let data = {
        userId: this.userId,
        moduleName: ["ticket", "commentTicket"]
      };

      try {
        await this.applyGetAllNotification(data).then(res => {
          this.unreadNotifications = [];
          this.unreadNotificationsMin = [];
          this.orderNotification(res.data.list);
        });
      } catch (err) {
        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach(item => {
              switch (item.code) {
                default:
                  this.acceptAlert(
                    "danger",
                    "Notification",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },

    redirection(notification) {
      switch (notification.moduleName) {
        case "ticket":
          this.$router.push({ name: "Ticket" }).catch(() => {});
          break;
        case "commentTicket":
          window.open(this.$router.resolve({
            name: "Comment",
            params: { ticketId: notification.itemId }
          }).href, "_blank");

          break;

        default:
          break;
      }
    },
    async readNotification(notification) {
      this.loading = true;
      let _this = this;
      let promises = [];
      let { list } = notification;
      list.forEach(function(el, index) {
        promises.push(
          (async function(el) {
            await _this.applyReadNotification({
              userId: _this.userId,
              notificationId: el.id
            });
          })(el)
        );
      });
      await Promise.all(promises).then(x => {
        this.notificationNumber -= notification.counter;
        const idx = _this.unreadNotifications.findIndex(
          ele => ele.id === notification.id
        );

        _this.unreadNotifications.splice(idx, 1);
        this.loading = false;
        _this.loadMinValue();
        _this.redirection(notification);
      });
    },

    loadMinValue() {
      this.hideViewAll = false;
      if (this.unreadNotifications.length > 5) {
        this.unreadNotifications.forEach((el, ind) => {
          ind < 5 ? this.unreadNotificationsMin.push(el) : "";
        });
      } else {
        this.unreadNotificationsMin = this.unreadNotifications;
      }
    },

    acceptAlert(color, title, text) {
      this.$vs.notify({ color, title, text });
    },
    elapsedTime(startTime) {
      // let x = startTime ;
      let x = new Date(startTime);

      let now = new Date();
      var timeDiff = now - x;
      timeDiff /= 1000;

      var seconds = Math.round(timeDiff);
      timeDiff = Math.floor(timeDiff / 60);

      var minutes = Math.round(timeDiff % 60);
      timeDiff = Math.floor(timeDiff / 60);

      var hours = Math.round(timeDiff % 24);
      timeDiff = Math.floor(timeDiff / 24);

      var days = Math.round(timeDiff % 365);
      timeDiff = Math.floor(timeDiff / 365);

      var years = timeDiff;

      if (years > 0) {
        return years + (years > 1 ? " Ans " : " An ") + "dejà";
      } else if (days > 0) {
        return days + (days > 1 ? " Jours " : " Jour ") + "dejà";
      } else if (hours > 0) {
        return hours + (hours > 1 ? " H " : " H ") + "dejà";
      } else if (minutes > 0) {
        return minutes + (minutes > 1 ? " Min " : " Min ") + "dejà";
      } else if (seconds > 0) {
        return seconds + (seconds > 1 ? " sec dejà" : "maintenant");
      }

      return "Maintenant";
    },
    // Method for creating dummy notification time
    randomDate({ hr, min, sec }) {
      let date = new Date();

      if (hr) date.setHours(date.getHours() - hr);
      if (min) date.setMinutes(date.getMinutes() - min);
      if (sec) date.setSeconds(date.getSeconds() - sec);

      return date;
    }
  }
};
</script>
