/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default [
  {
    url: "/",
    name: "Accueil",
    slug: "Accueil",
    icon: "HomeIcon"
  },
  {
    url: "/contrat",
    name: "Contrats",
    slug: "Contrats",
    icon: "BookOpenIcon"
  },
  {
    url: "/ticket",
    name: "Faire une demande",
    slug: "Faire une demande",
    icon: "BookmarkIcon"
  },
  
];
